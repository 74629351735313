import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';

import { ERequestStatus } from '@/shared/lib/types';
import {
  EServicesScreenPage,
  IClientServiceListItem,
  ICreateServiceServiceData,
  IDeepLinkListItem,
  IServiceListData,
  IServiceListItem,
  IServiceListVersionsData,
  IServicesScreenServiceData,
  TServicesScreenVisualizationData,
} from '../types';
import { serviceListData } from '../stub/serviceListData';
import { serviceListVersionsData } from '../stub/serviceListVersionsData';
import {
  fetchServicesScreenClientServices,
  fetchServicesScreenDeepLinkList,
  fetchServicesScreenService,
  fetchServicesScreenServiceList,
  fetchServicesScreenVersionList,
  fetchServicesScreenVisualizationData,
} from './servicesScreen.actions';
import { deepLinkListData } from '../stub/deepLinkListData';
import { serviceData } from '../stub/serviceData';
import { clientServicesData } from '../stub/clientServicesData';

interface IServicesScreenState {
  serviceListVersionsData: IServiceListVersionsData;
  fetchServicesScreenVersionListStatus: ERequestStatus;
  serviceListData: IServiceListData;
  serviceListDataInitial: IServiceListData;
  fetchServicesScreenServiceListStatus: ERequestStatus;
  servicesScreenVisualizationData: TServicesScreenVisualizationData;
  fetchServicesScreenVisualizationDataStatus: ERequestStatus;
  activePage: EServicesScreenPage;
  deepLinkList: IDeepLinkListItem[];
  fetchServicesScreenDeepLinkListStatus: ERequestStatus;
  clientServices: IClientServiceListItem[];
  fetchServicesScreenClientServicesStatus: ERequestStatus;
  editServiceService: IServicesScreenServiceData;
  fetchServicesScreenServiceStatus: ERequestStatus;
}

const initialState: IServicesScreenState = {
  serviceListVersionsData: null, // serviceListVersionsData,
  fetchServicesScreenVersionListStatus: ERequestStatus.idle,
  serviceListData: null, // serviceListData,
  serviceListDataInitial: null, // cloneDeep(serviceListData),
  fetchServicesScreenServiceListStatus: ERequestStatus.idle,
  servicesScreenVisualizationData: null,
  fetchServicesScreenVisualizationDataStatus: ERequestStatus.idle,
  activePage: EServicesScreenPage.serviceList,
  deepLinkList: [], // deepLinkListData.deep_links,
  fetchServicesScreenDeepLinkListStatus: ERequestStatus.idle,
  clientServices: [], // clientServicesData.services,
  fetchServicesScreenClientServicesStatus: ERequestStatus.idle,
  editServiceService: null, // serviceData,
  fetchServicesScreenServiceStatus: ERequestStatus.idle,
};

const servicesScreen = createSlice({
  name: 'servicesScreen',
  initialState,
  reducers: {
    setServicesScreenName: (state, action) => {
      state.serviceListData.name = action.payload;
    },
    updateServiceListItems: (state, action) => {
      state.serviceListData.services = action.payload;
    },
    clearServicesScreenVisualizationData: (state) => {
      state.servicesScreenVisualizationData = null;
      state.fetchServicesScreenVisualizationDataStatus = ERequestStatus.idle;
    },
    setServicesScreenActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    addClientServiceToSeviceList: (state, action) => {
      const clientServiceToAdd: IClientServiceListItem = action.payload;

      if (clientServiceToAdd) {
        state.serviceListData.services.push({
          id: clientServiceToAdd.service.id,
          image_url: clientServiceToAdd.service.image_url,
          name: clientServiceToAdd.service.name,
        });
      }
    },
    addNewServiceToSeviceList: (state, action) => {
      const newServiceToAdd: IServiceListItem = action.payload;

      state.serviceListData.services.push(newServiceToAdd);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchServicesScreenVersionList.pending, (state) => {
        state.fetchServicesScreenVersionListStatus = ERequestStatus.pending;
      })
      .addCase(fetchServicesScreenVersionList.fulfilled, (state, action) => {
        state.fetchServicesScreenVersionListStatus = ERequestStatus.success;
        state.serviceListVersionsData = action.payload;
      })
      .addCase(fetchServicesScreenVersionList.rejected, (state) => {
        state.fetchServicesScreenVersionListStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchServicesScreenServiceList.pending, (state) => {
        state.fetchServicesScreenServiceListStatus = ERequestStatus.pending;
      })
      .addCase(fetchServicesScreenServiceList.fulfilled, (state, action) => {
        state.fetchServicesScreenServiceListStatus = ERequestStatus.success;
        state.serviceListData = action.payload;
        state.serviceListDataInitial = cloneDeep(action.payload);
      })
      .addCase(fetchServicesScreenServiceList.rejected, (state) => {
        state.fetchServicesScreenServiceListStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchServicesScreenVisualizationData.pending, (state) => {
        state.fetchServicesScreenVisualizationDataStatus = ERequestStatus.pending;
      })
      .addCase(fetchServicesScreenVisualizationData.fulfilled, (state, action) => {
        state.fetchServicesScreenVisualizationDataStatus = ERequestStatus.success;
        state.servicesScreenVisualizationData = action.payload.services_screen;
      })
      .addCase(fetchServicesScreenVisualizationData.rejected, (state) => {
        state.fetchServicesScreenVisualizationDataStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchServicesScreenDeepLinkList.pending, (state) => {
        state.fetchServicesScreenDeepLinkListStatus = ERequestStatus.pending;
      })
      .addCase(fetchServicesScreenDeepLinkList.fulfilled, (state, action) => {
        state.fetchServicesScreenDeepLinkListStatus = ERequestStatus.success;
        state.deepLinkList = action.payload.deep_links;
      })
      .addCase(fetchServicesScreenDeepLinkList.rejected, (state) => {
        state.fetchServicesScreenDeepLinkListStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchServicesScreenClientServices.pending, (state) => {
        state.fetchServicesScreenClientServicesStatus = ERequestStatus.pending;
      })
      .addCase(fetchServicesScreenClientServices.fulfilled, (state, action) => {
        state.fetchServicesScreenClientServicesStatus = ERequestStatus.success;
        state.clientServices = action.payload.services;
      })
      .addCase(fetchServicesScreenClientServices.rejected, (state) => {
        state.fetchServicesScreenClientServicesStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchServicesScreenService.pending, (state) => {
        state.fetchServicesScreenServiceStatus = ERequestStatus.pending;
      })
      .addCase(fetchServicesScreenService.fulfilled, (state, action) => {
        state.fetchServicesScreenServiceStatus = ERequestStatus.success;
        state.editServiceService = action.payload;
      })
      .addCase(fetchServicesScreenService.rejected, (state) => {
        state.fetchServicesScreenServiceStatus = ERequestStatus.error;
      });
  },
});

export const {
  setServicesScreenName,
  updateServiceListItems,
  clearServicesScreenVisualizationData,
  setServicesScreenActivePage,
  addClientServiceToSeviceList,
  addNewServiceToSeviceList,
} = servicesScreen.actions;

export default servicesScreen.reducer;
