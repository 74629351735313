interface IShowSuccessNotificationProps {
  message: string;
  enqueueSnackbar: any;
}

export const showErrorNotification = ({
  message,
  enqueueSnackbar,
}: IShowSuccessNotificationProps) => {
  enqueueSnackbar(<div>{message}</div>, {
    variant: 'error',
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  });
};
