import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
import { DivKit } from '@divkitframework/react';
import isEmpty from 'lodash.isempty';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import { TRootState, useAppDispatch } from '@/store';
import {
  VersionContainer,
  VersionHeading,
  VersionControlsContainer,
  FooterControlsContainer,
  MainContentSection,
  LeftSection,
  RightSection,
  ServicesScreenHeadingTextField,
  PublishControlsLeftContainer,
  PublishControlsRightContainer,
} from './elements';
import { TextFieldGroup } from '@/shared/components/TextFieldGroup';
import { Box, CircularProgress } from '@mui/material';
import { MainButton } from '@/shared/components/MainButton';
import { ERequestStatus } from '@/shared/lib/types';
import { SecondaryButton } from '@/shared/components/SecondaryButton';

import Yup from '@/shared/validations';
import {
  clearServicesScreenVisualizationData,
  setServicesScreenActivePage,
  setServicesScreenName,
  updateServiceListItems,
} from '@/features/servicesScreen/redux/servicesScreen.slice';
import { ServiceList } from './components/ServiceList';
import {
  EServicesScreenPage,
  EServicesScreenPlatform,
  IServiceListItem,
} from '@/features/servicesScreen/types';
import {
  fetchServicesScreenVisualizationData,
  publishServicesScreenServiceList,
  saveServicesScreenVersion,
} from '@/features/servicesScreen/redux/servicesScreen.actions';
import {
  maxServicesAmount,
  publishingPlatformOptionTexts,
  publishingPlatformOptionValues,
} from '@/features/servicesScreen/const';
import { isServiceListTouched } from '@/features/servicesScreen/utils';
import { showSuccessNotification } from '@/shared/utils';
import { ROUTES } from '@/shared/lib/const';
import isEqual from 'lodash.isequal';
import { usePrevious } from '@/shared/hooks/usePrevious';
import CustomRadioGroup from '@/shared/components/CustomRadioGroup';

const validationSchema = Yup.object().shape({
  name: Yup.string().itemNameInputValidation(),
});

export interface IServiceScreenVersionContentProps {
  setActiveServiceId: (serviceId: number) => void;
}

export const ServiceScreenVersionContent: FC<IServiceScreenVersionContentProps> = ({
  setActiveServiceId,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { version } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const serviceListData = useSelector((state: TRootState) => state.servicesScreen.serviceListData);
  const serviceListDataInitial = useSelector(
    (state: TRootState) => state.servicesScreen.serviceListDataInitial
  );
  const [selectedPlatform, setSelectedPlatform] = useState<EServicesScreenPlatform>(
    EServicesScreenPlatform.both
  );
  const visualizationData = useSelector(
    (state: TRootState) => state.servicesScreen.servicesScreenVisualizationData
  );
  const fetchServicesScreenVisualizationDataStatus = useSelector(
    (state: TRootState) => state.servicesScreen.fetchServicesScreenVisualizationDataStatus
  );
  const previousServices: IServiceListItem[] = usePrevious(serviceListData?.services);
  const activePage = useSelector((state: TRootState) => state.servicesScreen.activePage);

  const isVisualizationDataLoading =
    fetchServicesScreenVisualizationDataStatus === ERequestStatus.idle ||
    fetchServicesScreenVisualizationDataStatus === ERequestStatus.pending;

  const isPublishButtonDisabled = useMemo(() => {
    return !selectedPlatform || serviceListData?.services?.length === 0;
  }, [selectedPlatform, serviceListData]);

  const isSaveOrderButtonDisabled = useMemo(() => {
    return serviceListData?.services?.length === 0;
  }, [serviceListData]);

  const isAddServiceBtnDisabled = useMemo(() => {
    return serviceListData?.services?.length >= maxServicesAmount;
  }, [serviceListData?.services]);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: (values, actions) => {},
  });

  useEffect(() => {
    if (serviceListData) {
      formik.setFieldValue('name', serviceListData.name);
    }
  }, [serviceListData]);

  useEffect(() => {
    dispatch(fetchServicesScreenVisualizationData());

    return () => {
      dispatch(clearServicesScreenVisualizationData());
    };
  }, [version, activePage]);

  useEffect(() => {
    if (previousServices && serviceListData?.services) {
      const previousServicesIds = previousServices.map((service) => {
        return service.id;
      });
      const servicesIdsData = serviceListData?.services.map((service) => {
        return service.id;
      });

      if (!isEqual(previousServicesIds, servicesIdsData)) {
        dispatch(fetchServicesScreenVisualizationData());
      }
    }
  }, [previousServices, serviceListData?.services]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setServicesScreenName(event.target.value));
    formik.setFieldValue('name', event.target.value);
  };

  const handleServiceListUpdate = (serviceListItems: IServiceListItem[]) => {
    dispatch(updateServiceListItems(serviceListItems));
  };

  const handlePublishServicesScreen = async () => {
    formik.setTouched({
      name: true,
    });
    formik.validateForm().then(async (errors) => {
      if (isEmpty(errors)) {
        if (isServiceListTouched({ serviceListData, serviceListDataInitial })) {
          dispatch(saveServicesScreenVersion())
            .unwrap()
            .then(async (data) => {
              const lastVersion = data.version.toString();
              await dispatch(
                publishServicesScreenServiceList({
                  version: lastVersion,
                  platform: selectedPlatform,
                })
              ).unwrap();
              showSuccessNotification({
                message: 'Экран Сервисы успешно опубликован',
                enqueueSnackbar,
              });
              navigate(ROUTES.servicesScreen.version.replace(':version', lastVersion));
            });
        } else {
          await dispatch(
            publishServicesScreenServiceList({ version, platform: selectedPlatform })
          ).unwrap();
          showSuccessNotification({
            message: 'Экран Сервисы успешно опубликован',
            enqueueSnackbar,
          });
        }
      }
    });
  };

  const handleSaveServicesScreenVersion = () => {
    formik.setTouched({
      name: true,
    });

    formik.validateForm().then(async (errors) => {
      if (isEmpty(errors)) {
        dispatch(saveServicesScreenVersion());
      }
    });
  };

  const handlePlatformChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedPlatform(value as EServicesScreenPlatform);
  };

  const handleAddIconBtnClick = () => {
    dispatch(setServicesScreenActivePage(EServicesScreenPage.addService));
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <VersionContainer>
          <VersionHeading>Экран сервисы</VersionHeading>
          <VersionControlsContainer>
            <MainContentSection>
              <LeftSection>
                <ServicesScreenHeadingTextField
                  label="Заголовок"
                  type="text"
                  size="small"
                  value={formik.values.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mb: '10px' }}
                  onChange={handleNameChange}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <ServiceList
                  setActiveServiceId={setActiveServiceId}
                  onUpdate={handleServiceListUpdate}
                ></ServiceList>
                <FooterControlsContainer>
                  <PublishControlsLeftContainer>
                    <CustomRadioGroup
                      label="Платформа"
                      value={selectedPlatform}
                      radioGroupValues={publishingPlatformOptionValues}
                      radioGroupTexts={publishingPlatformOptionTexts}
                      onChange={handlePlatformChange}
                      sxWrapper={{ mb: '5px' }}
                    />
                    <MainButton
                      sx={{ mb: 2, width: '150px' }}
                      disabled={isPublishButtonDisabled}
                      onClick={handlePublishServicesScreen}
                    >
                      Опубликовать
                    </MainButton>
                  </PublishControlsLeftContainer>
                  <PublishControlsRightContainer>
                    <SecondaryButton
                      sx={{ mb: 2, width: '190px' }}
                      startIcon={<AddCircleOutlineRoundedIcon style={{ fontSize: 24 }} />}
                      disabled={isAddServiceBtnDisabled}
                      onClick={handleAddIconBtnClick}
                    >
                      Добавить иконку
                    </SecondaryButton>
                    <SecondaryButton
                      sx={{ mb: 2, width: '190px' }}
                      disabled={isSaveOrderButtonDisabled}
                      onClick={handleSaveServicesScreenVersion}
                    >
                      Сохранить порядок
                    </SecondaryButton>
                  </PublishControlsRightContainer>
                </FooterControlsContainer>
              </LeftSection>
              <RightSection>
                <TextFieldGroup sx={{ mt: '-9px' }} title="Изображение экрана Сервисы">
                  {visualizationData ? (
                    <Box
                      sx={{
                        width: '400px',
                        background: '#ffffff',
                        border: '1px solid orange',
                      }}
                    >
                      <DivKit id="full-promotion" json={visualizationData} />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '400px',
                        height: '580px',
                      }}
                    >
                      {isVisualizationDataLoading ? (
                        <CircularProgress
                          // @ts-expect-error: Unreachable code error
                          color="textMain"
                          size="34px"
                        />
                      ) : null}
                    </Box>
                  )}
                </TextFieldGroup>
              </RightSection>
            </MainContentSection>
          </VersionControlsContainer>
        </VersionContainer>
      </Form>
    </FormikProvider>
  );
};
