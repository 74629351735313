import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import { ChangeEvent, FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';

import { UploadFileWithTextField } from '@/shared/components/UploadFileWithTextField';
import { TRootState, useAppDispatch } from '@/store';
import { setPromotionCatalogImage } from '../redux/promotions.slice';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { fetchPromotionCatalogUrl, setPromotionCatalogUrl } from '../redux/promotions.actions';
import { CatalogImage } from './elements';
import Yup from '@/shared/validations';
import { showSuccessNotification } from '@/shared/utils';

const validationSchema = Yup.object().shape({
  fileUrl: Yup.string().externalLinkInputValidation(),
});

export const CatalogTab: FC = () => {
  const dispatch = useAppDispatch();
  const promotionCatalog = useSelector((state: TRootState) => state.promotions.promotionCatalog);
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      fileUrl: '',
    },
    onSubmit: (values, actions) => {},
    validationSchema,
  });

  useEffect(() => {
    if (!isEmpty(promotionCatalog)) {
      formik.setFieldValue('fileUrl', promotionCatalog?.file_url || '');
    }
  }, [promotionCatalog]);

  useEffect(() => {
    dispatch(fetchPromotionCatalogUrl());
  }, []);

  const handlePromotionCatalogImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromotionCatalogImage(event.target.value));
    formik.setFieldValue('fileUrl', event.target.value);
  };
  const handlePromotionCatalogImageUpload = (url: string) => {
    dispatch(setPromotionCatalogImage(url));
    formik.setFieldValue('fileUrl', url);
  };

  const handleUploadCatalogBtnClick = () => {
    formik.setTouched({
      fileUrl: true,
    });
    formik.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        dispatch(setPromotionCatalogUrl())
          .unwrap()
          .then(() => {
            showSuccessNotification({ message: 'Каталог успешно сохранен', enqueueSnackbar });
          });
      }
    });
  };

  return (
    <Box sx={{ p: 3, minWidth: '740px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <UploadFileWithTextField
          label="Изображение для каталога"
          type="text"
          size="small"
          sx={{ mb: 2, mr: 2, width: '300px' }}
          value={formik.values.fileUrl}
          onChange={handlePromotionCatalogImageChange}
          handleFileUpload={handlePromotionCatalogImageUpload}
          error={formik.touched.fileUrl && !!formik.errors.fileUrl}
          helperText={formik.touched.fileUrl && formik.errors.fileUrl}
        />
        <SecondaryButton
          startIcon={<AddRoundedIcon style={{ fontSize: 25 }} />}
          onClick={handleUploadCatalogBtnClick}
        >
          Загрузить каталог
        </SecondaryButton>
      </Box>

      <Box>
        {promotionCatalog?.file_url ? <CatalogImage $src={promotionCatalog?.file_url} /> : null}
      </Box>
    </Box>
  );
};
