import { FC, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import {
  ServiceIcon,
  ServiceItemContainer,
  ServiceItemContent,
  ServiceItemFooter,
  ServiceItemName,
  ServiceItemNameContainer,
  ServiceListContainerSortable,
  SymbolDelete,
} from './elements';
import { TRootState } from '@/store';
import { EServicesScreenPage, IServiceListItem } from '@/features/servicesScreen/types';
import { ItemInterface } from 'react-sortablejs';
import { setServicesScreenActivePage } from '@/features/servicesScreen/redux/servicesScreen.slice';
import { useAppDispatch } from '@/store';

export interface IServiceListProps {
  onUpdate?: (serviceListItems: IServiceListItem[]) => void;
  setActiveServiceId: (serviceId: number) => void;
}

export const ServiceList: FC<IServiceListProps> = ({ onUpdate, setActiveServiceId }) => {
  const dispatch = useAppDispatch();
  const serviceListData = useSelector((state: TRootState) => state.servicesScreen.serviceListData);
  const [list, setListItems] = useState<IServiceListItem[]>([]);

  useEffect(() => {
    setListItems(cloneDeep(serviceListData?.services));
  }, [serviceListData?.services]);

  const handleDeleteServiceItem = ({
    event,
    serviceId,
  }: {
    event: MouseEvent<HTMLElement>;
    serviceId: number;
  }) => {
    const filteredList = list.filter((item) => {
      return item.id !== serviceId;
    });

    setListItems(filteredList);
    onUpdate?.(filteredList);
    event.stopPropagation();
  };

  const handleSetList = (items: ItemInterface[]) => {
    if (items.length !== 0) {
      setListItems(items as IServiceListItem[]);
      onUpdate?.(items as IServiceListItem[]);
    }
  };

  const handleServiceClick = (serviceId: number) => {
    setActiveServiceId(serviceId);
    dispatch(setServicesScreenActivePage(EServicesScreenPage.editService));
  };

  return (
    <ServiceListContainerSortable
      list={list}
      setList={handleSetList}
      // ghostClass="dropArea"
      handle=".dragHandle"
      filter=".ignoreDrag"
      preventOnFilter={true}
    >
      {list?.map((serviceItem) => {
        return (
          <ServiceItemContainer
            key={serviceItem.id}
            className="dragHandle"
            onClick={() => handleServiceClick(serviceItem.id)}
          >
            <ServiceItemContent>
              <SymbolDelete
                className="ignoreDrag"
                onClick={(event) => handleDeleteServiceItem({ event, serviceId: serviceItem.id })}
              >
                delete
              </SymbolDelete>
              <ServiceIcon $imgUrl={serviceItem.image_url} />
            </ServiceItemContent>
            <ServiceItemFooter>
              <ServiceItemNameContainer>
                <ServiceItemName>{serviceItem.name}</ServiceItemName>
              </ServiceItemNameContainer>
            </ServiceItemFooter>
          </ServiceItemContainer>
        );
      })}
    </ServiceListContainerSortable>
  );
};
