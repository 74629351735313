import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TRootState, useAppDispatch } from '@/store';
import { CircularProgressWrapper, VersionContainer } from './elements';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebarMenus/redux/services.slice';
import { ServiceScreenVersionContent } from './components/ServiceScreenVersionContent';
import {
  fetchServicesScreenServiceList,
  fetchServicesScreenVersionList,
} from '../redux/servicesScreen.actions';
import { CircularProgress } from '@mui/material';
import { ERequestStatus } from '@/shared/lib/types';
import { EServicesScreenPage } from '@/features/servicesScreen/types';
import AddService from './components/AddService';
import { setServicesScreenActivePage } from '@/features/servicesScreen/redux/servicesScreen.slice';
import EditService from './components/EditService';

const ServicesScreenVersion = () => {
  const { version } = useParams();
  const dispatch = useAppDispatch();
  const serviceListVersionsData = useSelector(
    (state: TRootState) => state.servicesScreen.serviceListVersionsData
  );
  const fetchServicesScreenServiceListStatus = useSelector(
    (state: TRootState) => state.servicesScreen.fetchServicesScreenServiceListStatus
  );
  const activePage = useSelector((state: TRootState) => state.servicesScreen.activePage);
  const [editServiceScreenServiceId, setEditServiceScreenServiceId] = useState<number>();

  const isShowLoader =
    fetchServicesScreenServiceListStatus === ERequestStatus.pending ||
    fetchServicesScreenServiceListStatus === ERequestStatus.idle;

  useEffect(() => {
    if (version) {
      dispatch(setMiddleMenuActiveBtn(version));
    }
  }, [dispatch, version]);

  useEffect(() => {
    if (serviceListVersionsData) {
      dispatch(setMiddleMenuOptions(serviceListVersionsData.versions));
    }
  }, [dispatch, serviceListVersionsData]);

  useEffect(() => {
    let fetchServicesScreenServiceListPromise: any;

    dispatch(fetchServicesScreenVersionList());
    if (version !== undefined) {
      fetchServicesScreenServiceListPromise = dispatch(fetchServicesScreenServiceList(version));
    }
    return () => {
      fetchServicesScreenServiceListPromise?.abort();
    };
  }, [dispatch, version]);

  useEffect(() => {
    dispatch(setServicesScreenActivePage(EServicesScreenPage.serviceList));
  }, [version]);

  return (
    <VersionContainer>
      {isShowLoader ? (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      ) : (
        (activePage === EServicesScreenPage.serviceList && (
          <ServiceScreenVersionContent setActiveServiceId={setEditServiceScreenServiceId} />
        )) ||
        (activePage === EServicesScreenPage.addService && <AddService />) ||
        (activePage === EServicesScreenPage.editService && (
          <EditService serviceId={editServiceScreenServiceId} />
        ))
      )}
    </VersionContainer>
  );
};

export default ServicesScreenVersion;
