import isEqual from 'lodash.isequal';

import { IServiceListData } from '../types';

export interface IIsServiceListTouchedProps {
  serviceListData: IServiceListData;
  serviceListDataInitial: IServiceListData;
}

export const isServiceListTouched = ({
  serviceListData,
  serviceListDataInitial,
}: IIsServiceListTouchedProps) => {
  return !isEqual(serviceListData, serviceListDataInitial);
};
