import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { TRootState, useAppDispatch } from '@/store';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import {
  EServiceActionType,
  EServicesScreenPage,
  EServicesScreenServiceActionType,
  ICreateServiceServiceData,
  IServiceListItem,
} from '@/features/servicesScreen/types';
import {
  addClientServiceToSeviceList,
  addNewServiceToSeviceList,
  setServicesScreenActivePage,
} from '@/features/servicesScreen/redux/servicesScreen.slice';
import {
  AddServiceContainer,
  AddServiceHeading,
  CircularProgressWrapper,
  CreateNewServiceContainer,
  CreateNewServiceSubHeading,
  SelectClientServiceContainer,
  ServiceActionValueTextField,
  ServiceNameTextField,
} from './elements';
import { CircularProgress } from '@mui/material';
import { ERequestStatus } from '@/shared/lib/types';
import {
  createServicesScreenService,
  fetchServicesScreenClientServices,
  fetchServicesScreenDeepLinkList,
} from '@/features/servicesScreen/redux/servicesScreen.actions';
import { CustomSelect } from '@/shared/components/CustomSelect';
import { MainButton } from '@/shared/components/MainButton';
import {
  maxServicesAmount,
  serviceActionTypeOptionTexts,
  serviceActionTypeOptionValues,
} from '@/features/servicesScreen/const';
import Yup from '@/shared/validations';
import { UploadFileWithTextField } from '@/shared/components/UploadFileWithTextField';
import isEmpty from 'lodash.isempty';
import { showErrorNotification, showSuccessNotification } from '@/shared/utils';
import cloneDeep from 'lodash.clonedeep';

const validationSchema = Yup.object().shape({
  name: Yup.string().itemNameInputValidation(),
  imageUrl: Yup.string().externalLinkInputValidation(),
  actionType: Yup.string().itemActionTypeValidation(),
  actionValue: Yup.string().externalLinkInputValidation(),
  deepLinkId: Yup.string().externalLinkInputValidation(),
});

const AddService = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const fetchServicesScreenClientServicesStatus = useSelector(
    (state: TRootState) => state.servicesScreen.fetchServicesScreenClientServicesStatus
  );
  const clientServices = useSelector((state: TRootState) => state.servicesScreen.clientServices);
  const serviceListData = useSelector((state: TRootState) => state.servicesScreen.serviceListData);
  const deepLinkList = useSelector((state: TRootState) => state.servicesScreen.deepLinkList);
  const fetchServicesScreenDeepLinkListStatus = useSelector(
    (state: TRootState) => state.servicesScreen.fetchServicesScreenDeepLinkListStatus
  );
  const [clientServiceIds, setClientServiceIds] = useState([]);
  const [clientServiceNames, setSlientServiceNames] = useState([]);
  const [selectedClientServiceId, setSelectedClientServiceId] = useState<string>();

  const isShowLoader =
    fetchServicesScreenClientServicesStatus === ERequestStatus.idle ||
    fetchServicesScreenClientServicesStatus === ERequestStatus.pending ||
    fetchServicesScreenDeepLinkListStatus === ERequestStatus.idle ||
    fetchServicesScreenDeepLinkListStatus === ERequestStatus.pending;

  const isAddClientServiceBtnDisabled = useMemo(() => {
    return (
      !selectedClientServiceId ||
      serviceListData?.services?.length >= maxServicesAmount ||
      clientServices?.length === 0
    );
  }, [selectedClientServiceId, serviceListData, clientServices]);

  const isAddClientServiceDropdownDisabled = useMemo(() => {
    return serviceListData?.services?.length >= maxServicesAmount || clientServices?.length === 0;
  }, [serviceListData, clientServices]);

  useMemo(() => {
    if (clientServices) {
      const clientServiceIdsReceived: Array<number> = [];
      const clientServiceNamesReceived: Array<string> = [];

      clientServices.forEach((serviceItem) => {
        clientServiceIdsReceived.push(serviceItem.service.id);
        clientServiceNamesReceived.push(serviceItem.service.name);
      });

      setClientServiceIds(clientServiceIdsReceived);
      setSlientServiceNames(clientServiceNamesReceived);
    }
  }, [clientServices]);

  const serviceDeepLinkOptionValues = useMemo(() => {
    if (deepLinkList) {
      const deepLinkIds = deepLinkList.map((deepLink) => {
        return deepLink.id;
      });

      return deepLinkIds;
    }
    return [];
  }, [deepLinkList]);

  const serviceDeepLinkOptionTexts = useMemo(() => {
    if (deepLinkList) {
      const deepLinkNames = deepLinkList.map((deepLink) => {
        return deepLink.icon_name;
      });

      return deepLinkNames;
    }
    return [];
  }, [deepLinkList]);

  const serviceDeepLinkOptionSubtitles = useMemo(() => {
    if (deepLinkList) {
      const deepLinkActionValues = deepLinkList.map((deepLink) => {
        return deepLink.action_value;
      });

      return deepLinkActionValues;
    }
    return [];
  }, [deepLinkList]);

  const formik = useFormik({
    initialValues: {
      name: '',
      imageUrl: '',
      actionType: '',
      actionValue: '',
      deepLinkId: '',
    },
    validationSchema,
    onSubmit: (values, actions) => {},
  });

  useEffect(() => {
    dispatch(fetchServicesScreenClientServices());
    dispatch(fetchServicesScreenDeepLinkList());
  }, []);

  const handleCancelBtnClick = () => {
    dispatch(setServicesScreenActivePage(EServicesScreenPage.serviceList));
  };

  const handleClientServiceChange = (value: string) => {
    setSelectedClientServiceId(value);
  };

  const handleAddClientServiceBtnClick = () => {
    const serviceIdToAdd = Number(selectedClientServiceId);

    const clientServiceToAdd = clientServices.find((sevice) => {
      return sevice.service.id === serviceIdToAdd;
    });
    const existingServiceListService = serviceListData?.services.find((service) => {
      return service.id === clientServiceToAdd.service.id;
    });
    if (!existingServiceListService) {
      dispatch(addClientServiceToSeviceList(clientServiceToAdd));
      showSuccessNotification({
        message: 'Сервис успешно добавлен',
        enqueueSnackbar,
      });
      dispatch(setServicesScreenActivePage(EServicesScreenPage.serviceList));
    } else {
      showErrorNotification({
        message: 'Сервис уже существует в списке',
        enqueueSnackbar,
      });
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('name', event.target.value);
  };

  const handleImageUrlInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('imageUrl', event.target.value);
  };

  const handleImageUpload = (url: string) => {
    formik.setFieldValue('imageUrl', url);
  };

  const handleActionTypeChange = (value: string) => {
    formik.setFieldValue('actionType', value);
  };

  const handleActionValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('actionValue', event.target.value);
  };

  const handleDeepLinkIdChange = (value: string) => {
    const deepLinkId = Number(value);
    const deepLinkFound = deepLinkList.find((deepLink) => {
      return deepLink.id === deepLinkId;
    });

    formik.setFieldValue('imageUrl', deepLinkFound.image_url);
    formik.setFieldValue('deepLinkId', value);
  };

  const handleCreateAndAddServiceBtnClick = () => {
    formik.setTouched({
      name: true,
      imageUrl: true,
      actionType: true,
      actionValue: true,
      deepLinkId: true,
    });
    formik.validateForm().then(async (errors) => {
      const errorsClone = cloneDeep(errors);
      const isDeepLinkActionTypeSelected = formik.values.actionType === EServiceActionType.deepLink;
      if (isDeepLinkActionTypeSelected) {
        delete errorsClone['actionValue'];
      } else {
        delete errorsClone['deepLinkId'];
      }

      if (isEmpty(errorsClone)) {
        const service: ICreateServiceServiceData = {
          name: formik.values.name,
          image_url: formik.values.imageUrl,
          on_click: {
            action_type: formik.values.actionType as EServicesScreenServiceActionType,
            action_value: null,
          },
        };

        if (isDeepLinkActionTypeSelected) {
          const deepLinkFound = deepLinkList.find((deepLink) => {
            return deepLink.id === Number(formik.values.deepLinkId);
          });

          service.on_click.action_value = deepLinkFound.action_value;
          service.on_click.deep_link_id = deepLinkFound.id;
        } else {
          service.on_click.action_value = formik.values.actionValue;
        }

        dispatch(createServicesScreenService(service))
          .unwrap()
          .then((data) => {
            const service: IServiceListItem = {
              id: data.id,
              name: formik.values.name,
              image_url: formik.values.imageUrl,
            };
            dispatch(addNewServiceToSeviceList(service));
            showSuccessNotification({
              message: 'Сервис успешно создан',
              enqueueSnackbar,
            });
            dispatch(setServicesScreenActivePage(EServicesScreenPage.serviceList));
          });
      }
    });
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        {isShowLoader ? (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        ) : (
          <AddServiceContainer>
            <AddServiceHeading>Добавление иконки</AddServiceHeading>
            <SelectClientServiceContainer>
              <CustomSelect
                label="Выбрать из готовых"
                optionValues={clientServiceIds}
                optionTexts={clientServiceNames}
                sx={{ maxWidth: '260px', mr: '20px' }}
                sxSelectMenu={{ maxHeight: '400px' }}
                disabled={isAddClientServiceDropdownDisabled}
                value={selectedClientServiceId}
                onChange={handleClientServiceChange}
              />
              <MainButton
                sx={{ mb: 2, width: '190px' }}
                disabled={isAddClientServiceBtnDisabled}
                onClick={handleAddClientServiceBtnClick}
              >
                Добавить
              </MainButton>
            </SelectClientServiceContainer>
            <CreateNewServiceContainer>
              <CreateNewServiceSubHeading>Создать новую иконку</CreateNewServiceSubHeading>
              <ServiceNameTextField
                label="Название"
                type="text"
                size="small"
                value={formik.values.name}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mb: '20px' }}
                onChange={handleNameChange}
                error={formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
              />
              <UploadFileWithTextField
                label="Ссылка на картинку"
                type="text"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mb: '20px' }}
                value={formik.values.imageUrl}
                name="imageUrl"
                onChange={handleImageUrlInputChange}
                handleFileUpload={handleImageUpload}
                error={!!formik.touched.imageUrl && !!formik.errors.imageUrl}
                helperText={!!formik.touched.imageUrl && formik.errors.imageUrl}
              />
              <CustomSelect
                label="Тип перехода"
                optionValues={serviceActionTypeOptionValues}
                optionTexts={serviceActionTypeOptionTexts}
                sx={{ maxWidth: '260px', mb: '20px' }}
                value={formik.values.actionType}
                onChange={handleActionTypeChange}
                error={!!formik.touched.actionType && !!formik.errors.actionType}
                helperText={!!formik.touched.actionType && formik.errors.actionType}
              />

              {formik.values.actionType !== EServiceActionType.deepLink ? (
                <ServiceActionValueTextField
                  label="Значение перехода"
                  type="text"
                  size="small"
                  value={formik.values.actionValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mb: '20px' }}
                  onChange={handleActionValueChange}
                  error={formik.touched.actionValue && !!formik.errors.actionValue}
                  helperText={formik.touched.actionValue && formik.errors.actionValue}
                />
              ) : (
                <CustomSelect
                  label="Тип перехода (диплинк)"
                  optionValues={serviceDeepLinkOptionValues}
                  optionTexts={serviceDeepLinkOptionTexts}
                  optionSubtitles={serviceDeepLinkOptionSubtitles}
                  sx={{ maxWidth: '260px', mb: '20px' }}
                  sxSelectMenu={{ maxHeight: '400px' }}
                  value={formik.values.deepLinkId}
                  onChange={handleDeepLinkIdChange}
                  error={!!formik.touched.deepLinkId && !!formik.errors.deepLinkId}
                  helperText={!!formik.touched.deepLinkId && formik.errors.deepLinkId}
                />
              )}
            </CreateNewServiceContainer>
            <MainButton sx={{ mb: 2, width: '190px' }} onClick={handleCreateAndAddServiceBtnClick}>
              Сохранить
            </MainButton>
            <SecondaryButton sx={{ mb: 2, width: '190px' }} onClick={handleCancelBtnClick}>
              Отмена
            </SecondaryButton>
          </AddServiceContainer>
        )}
      </Form>
    </FormikProvider>
  );
};

export default AddService;
