import Box from '@mui/material/Box';
import styled from 'styled-components';

export const Subtitle = styled(Box)`
  display: flex;
  font-size: 14px;
  color: ${({ theme }) => {
    return theme.colors.customSelect.subtitleText;
  }};
`;
