import styled from 'styled-components';

export const ServiceItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.075);
  user-select: none;
`;
