import styled from 'styled-components';

export const SymbolDelete = styled.span.attrs(() => ({
  className: 'material-symbols-rounded',
}))`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text.main};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.actionButton.hover};
  }
`;
