import { DivKitProps } from '@divkitframework/react/typings/divkit';

export enum EServiceListVersionStatus {
  draft = 'draft',
  published_now = 'published_now',
  published_previously = 'published_previously',
}

export enum EServiceListVersionStatusName {
  draft = 'Черновик',
  published_now = 'Опубликована',
  published_previously = 'Публиковалась',
}

export enum EServicesScreenPlatform {
  both = 'both',
  android = 'android',
  ios = 'ios',
}

export enum EServicesScreenPlatformName {
  both = 'Обе',
  android = 'Android',
  ios = 'iOS',
}

export interface IServiceListVersionsItem {
  name: string;
  datetime: string;
  author: string;
  status: EServiceListVersionStatus;
  platform?: EServicesScreenPlatform;
  version: number;
}

export interface IServiceListVersionsData {
  versions: Array<IServiceListVersionsItem>;
}

export interface IFetchServicesScreenVersionListResponse {
  data: IServiceListVersionsData;
}

export interface IServiceListItem {
  id: number;
  image_url: string;
  name: string;
}

export interface IServiceListData {
  last_version: number;
  name: string;
  services: Array<IServiceListItem>;
}

export interface IFetchServicesScreenServiceListResponse {
  data: IServiceListData;
}

export interface IPublishServicesScreenServiceListResponse {
  status: 'ok';
}

export interface ISaveServicesScreenVersionResponse {
  data: {
    version: number;
  };
  status: 'ok';
}

export type TServicesScreenVisualizationData = DivKitProps['json'];

export interface IFetchServicesScreenVisualizationDataResponse {
  data: {
    services_screen: TServicesScreenVisualizationData;
  };
}

export enum EServicesScreenPage {
  serviceList = 'serviceList',
  addService = 'addService',
  editService = 'editService',
}

export interface IDeepLinkListItem {
  id: number;
  icon_name: string;
  image_url: string;
  action_value: string;
}

export interface IDeepLinkListData {
  deep_links: IDeepLinkListItem[];
}

export interface IFetchServicesScreenDeepLinkListResponse {
  data: IDeepLinkListData;
}

export enum EServicesScreenServiceActionType {
  deep_link = 'deep_link',
  browser = 'browser',
  webview = 'webview',
}

export interface IClientServiceListItem {
  version: number;
  service: {
    id: number;
    name: string;
    image_url: string;
    on_click: {
      action_type: EServicesScreenServiceActionType;
      action_value: string;
      deep_link_id?: number;
    };
  };
}

export interface IClientServicesData {
  services: IClientServiceListItem[];
}
export interface IFetchServicesScreenClientServicesResponse {
  data: IClientServicesData;
}

export interface IServicesScreenServiceData {
  version?: number;
  service: {
    id?: number;
    name: string;
    image_url: string;
    on_click: {
      action_type: EServicesScreenServiceActionType;
      action_value: string;
      deep_link_id?: number;
    };
  };
}

export interface IFetchServicesScreenServiceResponse {
  data: IServicesScreenServiceData;
}

export interface ICreateServiceServiceData {
  id?: number;
  name: string;
  image_url: string;
  on_click: {
    action_type: EServicesScreenServiceActionType;
    action_value: string;
    deep_link_id?: number;
  };
}

export enum EServiceActionType {
  deepLink = 'deep_link',
  browser = 'browser',
  webview = 'webview',
}

export enum EServiceActionName {
  deepLink = 'Диплинк',
  browser = 'Браузер',
  webview = 'Вебвью',
}

export interface ISaveServicesScreenServiceResponse {
  data: {
    version: number;
    id: number;
  };
  status: 'ok';
}

export interface ISaveServicePayload {
  data: IServicesScreenServiceData;
}
