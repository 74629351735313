import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { TRootState, useAppDispatch } from '@/store';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import {
  EServiceActionType,
  EServicesScreenPage,
  EServicesScreenServiceActionType,
  IServiceListItem,
  IServicesScreenServiceData,
} from '@/features/servicesScreen/types';
import {
  addNewServiceToSeviceList,
  setServicesScreenActivePage,
} from '@/features/servicesScreen/redux/servicesScreen.slice';
import {
  CircularProgressWrapper,
  EditServiceContainer,
  EditServiceHeading,
  ServiceActionValueTextField,
  ServiceNameTextField,
} from './elements';
import { CircularProgress } from '@mui/material';
import { ERequestStatus } from '@/shared/lib/types';
import {
  fetchServicesScreenDeepLinkList,
  fetchServicesScreenService,
  updateServicesScreenService,
} from '@/features/servicesScreen/redux/servicesScreen.actions';
import { CustomSelect } from '@/shared/components/CustomSelect';
import { MainButton } from '@/shared/components/MainButton';
import {
  serviceActionTypeOptionTexts,
  serviceActionTypeOptionValues,
} from '@/features/servicesScreen/const';
import Yup from '@/shared/validations';
import { UploadFileWithTextField } from '@/shared/components/UploadFileWithTextField';
import isEmpty from 'lodash.isempty';
import { showSuccessNotification } from '@/shared/utils';
import cloneDeep from 'lodash.clonedeep';

const validationSchema = Yup.object().shape({
  name: Yup.string().itemNameInputValidation(),
  imageUrl: Yup.string().externalLinkInputValidation(),
  actionType: Yup.string().itemActionTypeValidation(),
  actionValue: Yup.string().externalLinkInputValidation(),
  deepLinkId: Yup.string().externalLinkInputValidation(),
});

export interface IEditServiceProps {
  serviceId: number;
}

const EditService: FC<IEditServiceProps> = ({ serviceId }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const fetchServicesScreenServiceStatus = useSelector(
    (state: TRootState) => state.servicesScreen.fetchServicesScreenServiceStatus
  );
  const deepLinkList = useSelector((state: TRootState) => state.servicesScreen.deepLinkList);
  const fetchServicesScreenDeepLinkListStatus = useSelector(
    (state: TRootState) => state.servicesScreen.fetchServicesScreenDeepLinkListStatus
  );
  const editServiceService = useSelector(
    (state: TRootState) => state.servicesScreen.editServiceService
  );

  const isShowLoader =
    fetchServicesScreenServiceStatus === ERequestStatus.idle ||
    fetchServicesScreenServiceStatus === ERequestStatus.pending ||
    fetchServicesScreenDeepLinkListStatus === ERequestStatus.idle ||
    fetchServicesScreenDeepLinkListStatus === ERequestStatus.pending;

  const serviceDeepLinkOptionValues = useMemo(() => {
    if (deepLinkList) {
      const deepLinkIds = deepLinkList.map((deepLink) => {
        return deepLink.id;
      });

      return deepLinkIds;
    }
    return [];
  }, [deepLinkList]);

  const serviceDeepLinkOptionTexts = useMemo(() => {
    if (deepLinkList) {
      const deepLinkNames = deepLinkList.map((deepLink) => {
        return deepLink.icon_name;
      });

      return deepLinkNames;
    }
    return [];
  }, [deepLinkList]);

  const serviceDeepLinkOptionSubtitles = useMemo(() => {
    if (deepLinkList) {
      const deepLinkActionValues = deepLinkList.map((deepLink) => {
        return deepLink.action_value;
      });

      return deepLinkActionValues;
    }
    return [];
  }, [deepLinkList]);

  const formik = useFormik({
    initialValues: {
      name: '',
      imageUrl: '',
      actionType: '',
      actionValue: '',
      deepLinkId: '',
    },
    validationSchema,
    onSubmit: (values, actions) => {},
  });

  useEffect(() => {
    if (serviceId !== undefined && serviceId !== null) {
      dispatch(fetchServicesScreenService(serviceId.toString()))
        .unwrap()
        .then((data) => {
          if (data.service?.name) {
            formik.setFieldValue('name', data.service?.name);
          }
          if (data.service?.image_url) {
            formik.setFieldValue('imageUrl', data.service?.image_url);
          }
          if (data.service?.on_click?.action_type) {
            formik.setFieldValue('actionType', data.service?.on_click?.action_type);
          }
          if (data.service?.on_click?.action_value) {
            formik.setFieldValue('actionValue', data.service?.on_click?.action_value);
          }
          if (data.service?.on_click?.deep_link_id) {
            formik.setFieldValue('deepLinkId', data.service?.on_click?.deep_link_id);
          }
        });
    }
  }, [serviceId]);

  useEffect(() => {
    dispatch(fetchServicesScreenDeepLinkList());
  }, []);

  const handleCancelBtnClick = () => {
    dispatch(setServicesScreenActivePage(EServicesScreenPage.serviceList));
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('name', event.target.value);
  };

  const handleImageUrlInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('imageUrl', event.target.value);
  };

  const handleImageUpload = (url: string) => {
    formik.setFieldValue('imageUrl', url);
  };

  const handleActionTypeChange = (value: string) => {
    formik.setFieldValue('actionType', value);
  };

  const handleActionValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('actionValue', event.target.value);
  };

  const handleDeepLinkIdChange = (value: string) => {
    const deepLinkId = Number(value);
    const deepLinkFound = deepLinkList.find((deepLink) => {
      return deepLink.id === deepLinkId;
    });

    formik.setFieldValue('imageUrl', deepLinkFound.image_url);
    formik.setFieldValue('deepLinkId', value);
  };

  const handleSaveServiceBtnClick = () => {
    formik.setTouched({
      name: true,
      imageUrl: true,
      actionType: true,
      actionValue: true,
      deepLinkId: true,
    });
    formik.validateForm().then(async (errors) => {
      const errorsClone = cloneDeep(errors);
      const isDeepLinkActionTypeSelected = formik.values.actionType === EServiceActionType.deepLink;
      if (isDeepLinkActionTypeSelected) {
        delete errorsClone['actionValue'];
      } else {
        delete errorsClone['deepLinkId'];
      }

      if (isEmpty(errorsClone)) {
        const service: IServicesScreenServiceData = cloneDeep(editServiceService);

        service.service.name = formik.values.name;
        service.service.image_url = formik.values.imageUrl;
        service.service.on_click.action_type = formik.values
          .actionType as EServicesScreenServiceActionType;

        if (isDeepLinkActionTypeSelected) {
          const deepLinkFound = deepLinkList.find((deepLink) => {
            return deepLink.id === Number(formik.values.deepLinkId);
          });

          service.service.on_click.action_value = deepLinkFound.action_value;
          service.service.on_click.deep_link_id = deepLinkFound.id;
        } else {
          service.service.on_click.action_value = formik.values.actionValue;
        }

        dispatch(updateServicesScreenService(service))
          .unwrap()
          .then((data) => {
            const service: IServiceListItem = {
              id: data.id,
              name: formik.values.name,
              image_url: formik.values.imageUrl,
            };
            dispatch(addNewServiceToSeviceList(service));
            showSuccessNotification({
              message: 'Сервис успешно создан',
              enqueueSnackbar,
            });
            dispatch(setServicesScreenActivePage(EServicesScreenPage.serviceList));
          });
      }
    });
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        {isShowLoader ? (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        ) : (
          <EditServiceContainer>
            <EditServiceHeading>Создать новую иконку</EditServiceHeading>
            <ServiceNameTextField
              label="Название"
              type="text"
              size="small"
              value={formik.values.name}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mb: '20px' }}
              onChange={handleNameChange}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
            />
            <UploadFileWithTextField
              label="Ссылка на картинку"
              type="text"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mb: '20px' }}
              value={formik.values.imageUrl}
              name="imageUrl"
              onChange={handleImageUrlInputChange}
              handleFileUpload={handleImageUpload}
              error={!!formik.touched.imageUrl && !!formik.errors.imageUrl}
              helperText={!!formik.touched.imageUrl && formik.errors.imageUrl}
            />
            <CustomSelect
              label="Тип перехода"
              optionValues={serviceActionTypeOptionValues}
              optionTexts={serviceActionTypeOptionTexts}
              sx={{ maxWidth: '260px', mb: '20px' }}
              value={formik.values.actionType}
              onChange={handleActionTypeChange}
              error={!!formik.touched.actionType && !!formik.errors.actionType}
              helperText={!!formik.touched.actionType && formik.errors.actionType}
            />

            {formik.values.actionType !== EServiceActionType.deepLink ? (
              <ServiceActionValueTextField
                label="Значение перехода"
                type="text"
                size="small"
                value={formik.values.actionValue}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mb: '20px' }}
                onChange={handleActionValueChange}
                error={formik.touched.actionValue && !!formik.errors.actionValue}
                helperText={formik.touched.actionValue && formik.errors.actionValue}
              />
            ) : (
              <CustomSelect
                label="Тип перехода (диплинк)"
                optionValues={serviceDeepLinkOptionValues}
                optionTexts={serviceDeepLinkOptionTexts}
                optionSubtitles={serviceDeepLinkOptionSubtitles}
                sx={{ maxWidth: '260px', mb: '20px' }}
                sxSelectMenu={{ maxHeight: '400px' }}
                value={formik.values.deepLinkId}
                onChange={handleDeepLinkIdChange}
                error={!!formik.touched.deepLinkId && !!formik.errors.deepLinkId}
                helperText={!!formik.touched.deepLinkId && formik.errors.deepLinkId}
              />
            )}
            <MainButton sx={{ mb: 2, width: '190px' }} onClick={handleSaveServiceBtnClick}>
              Сохранить
            </MainButton>
            <SecondaryButton sx={{ mb: 2, width: '190px' }} onClick={handleCancelBtnClick}>
              Отмена
            </SecondaryButton>
          </EditServiceContainer>
        )}
      </Form>
    </FormikProvider>
  );
};

export default EditService;
